<template>
  <div>
    <i class="fas fa-times" @click="$emit(`swiftSide`)"></i>
    <div class="logo">
      <img alt="logo" src="@/assets/logo1.png" class="mw-100" />
    </div>
    <nav>
      <ul>
        <div
          v-if="user.role >= 60"
          id="custom-drop"
          class="cr-pointer"
          :class="hover == true ? 'show-in-mobile' : ''"
          @click="(hover = !hover), (hoverMatching = false)"
        >
          <el-popover
            :visible-arrow="false"
            placement="right-start"
            width="200"
            trigger="hover"
            popper-class="popover-content"
          >
            <div class="in-popover">
              <router-link
                to="/master-data/admin"
                :class="$route.params.typePage == 'admin' ? 'active' : ''"
                >Admin
              </router-link>
              <router-link
                to="/master-data/auditor"
                :class="$route.params.typePage == 'auditor' ? 'active' : ''"
                >Auditor</router-link
              >
              <router-link
                to="/master-data/clients"
                :class="$route.params.typePage == 'clients' ? 'active' : ''"
                >Clients</router-link
              >
            </div>

            <a
              class="reference"
              slot="reference"
              :class="$route.meta.active == 'master-data' ? classActive : ''"
            >
              <li>
                <img
                  alt="logo"
                  src="@/assets/icon/master-data.svg"
                  class="mw-100"
                />
                <span>Master Data</span>
              </li>
            </a>
          </el-popover>
        </div>

        <div class="dropdown-content" v-if="user.role >= 60 && hover">
          <router-link
            to="/master-data/admin"
            :class="$route.params.typePage == 'admin' ? 'active' : ''"
            >Admin
          </router-link>
          <router-link
            to="/master-data/auditor"
            :class="$route.params.typePage == 'auditor' ? 'active' : ''"
            >Auditor</router-link
          >
          <router-link
            to="/master-data/clients"
            :class="$route.meta.typePage == 'clients' ? 'active' : ''"
            >Clients</router-link
          >
        </div>

        <router-link
          v-if="user.role >= 60"
          to="/certification-requisition"
          :class="$route.meta.active == 'requisition' ? classActive : ''"
          ><li>
            <img
              alt="logo"
              src="@/assets/icon/requisition.svg"
              class="mw-100"
            />
            <span>Certification Requisition</span>
          </li>
        </router-link>
        <!-- matching -->
        <div
          id="custom-drop1"
          class="cr-pointer"
          :class="hoverMatching == true ? 'show-in-mobile' : ''"
          @click="(hoverMatching = !hoverMatching), (hover = false)"
          v-if="user.role >= 60"
        >
          <el-popover
            :visible-arrow="false"
            placement="right-start"
            width="200"
            trigger="hover"
            popper-class="popover-content"
          >
            <div class="in-popover">
              <router-link
                to="/matching"
                :class="$route.meta.active == 'matching' ? 'active' : ''"
                >Matching
              </router-link>
              <router-link
                to="/matching/assessment-plan"
                :class="$route.params.typePage == 'assessment' ? 'active' : ''"
                >Assessment plan</router-link
              >
            </div>

            <a
              class="reference"
              slot="reference"
              :class="
                $route.meta.active == 'matching' ||
                $route.meta.typePage == 'assessment'
                  ? classActive
                  : ''
              "
            >
              <li>
                <img
                  alt="logo"
                  src="@/assets/icon/matching.svg"
                  class="mw-100"
                />
                <span>Matching</span>
              </li>
            </a>
          </el-popover>
        </div>

        <div class="dropdown-content1" v-if="user.role >= 60 && hoverMatching">
          <router-link
            to="/matching"
            :class="$route.meta.active == 'matching' ? 'active' : ''"
            >Matching
          </router-link>
          <router-link
            to="/matching/assessment-plan"
            :class="$route.params.typePage == 'assessment' ? 'active' : ''"
            >Assessment plan</router-link
          >
        </div>
        <router-link
          v-if="user.role >= 50"
          to="/calendar"
          :class="$route.meta.active == 'calendar' ? classActive : ''"
          ><li>
            <img alt="logo" src="@/assets/icon/calendar.svg" class="mw-100" />
            <span>Calendar</span>
          </li>
        </router-link>
        <router-link
          v-if="user.role == 80"
          to="/certification"
          :class="$route.meta.active == 'certification' ? classActive : ''"
          ><li>
            <img
              alt="logo"
              src="@/assets/icon/certification.svg"
              class="mw-100"
            />
            <span>Certification</span>
          </li>
        </router-link>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  data() {
    return {
      hover: false,
      hoverMatching: false,
      classActive: "router-link-exact-active router-link-active",
    };
  },
};
</script>
<style lang="scss">
#custom-drop,
#custom-drop1 {
  &.show-in-mobile {
    .reference {
      li {
        @media (max-width: 767.98px) {
          margin-bottom: 185px;
        }
      }
    }

    & + .dropdown-content {
      top: 55px;
      transition: all 0.3s;
      opacity: 1;
    }
    & + .dropdown-content1 {
      top: 205px;
      transition: all 0.3s;
      opacity: 1;
    }
  }
}
.popover-content {
  padding: 0;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
  border-radius: 100px;
  background: transparent;
  border: none;
  .in-popover {
    @media (max-width: 767.98px) {
      display: none;
    }
  }
  a {
    display: block;
    padding: 16.5px 25px;
    color: black;
    font-size: 16px;
    text-decoration: none;
    display: block;
    background: #ffffff;
    text-align: left;
    &:first-child {
      border-radius: 0px 20px 0px 0px;
    }
    &:last-child {
      border-radius: 0px 0px 20px 0px;
    }
    &:hover,
    &.active {
      background: linear-gradient(180deg, #3369b9 10.3%, #001a40 110%);
      color: #fff;
    }
  }
}

#custom-drop1 {
  &.show-in-mobile {
    .reference {
      li {
        @media (max-width: 767.98px) {
          margin-bottom: 125px;
        }
      }
    }
  }
}
.dropdown-content,
.dropdown-content1 {
  @media (min-width: 768px) {
    display: none;
  }
  position: absolute;
  opacity: 0;
  top: 50px;
  z-index: 10;
  width: calc(100% - 10px);
  a {
    display: block;
    padding: 15px 0;
    padding-left: 55px;
    font-size: 16px;
    color: #fff;
    & + a {
      margin-top: 8px;
    }
    &.active,
    &:hover {
      background: rgba(0, 0, 0, 0.25);
      border-radius: 30px 0px 0px 30px;
    }
  }
}
</style>
