<template>
  <div>
    <VueHtml2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="myPDF"
      :pdf-quality="0.3"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      :html-to-pdf-options="htmlToPdfOptions"
      ref="html2Pdf"
      @progress="($event) => $emit('onProgress', $event)"
    >
      <section slot="pdf-content">
        <slot />
      </section>
    </VueHtml2pdf>
  </div>
</template>
<script>
import VueHtml2pdf from "vue-html2pdf";
export default {
  components: {
    VueHtml2pdf,
  },
  props: {
    generatePdf: {
      type: Boolean,
      default: false,
    },
    nameFile: {
      type: String,
      default: "",
    },
  },
  watch: {
    generatePdf() {
      if (this.generatePdf == true) {
        this.$refs.html2Pdf.generatePdf();
      }
    },
  },
  computed: {
    htmlToPdfOptions() {
      let setFileName =
        this.nameFile != ""
          ? `${this.nameFile.replace(/ /gi, "_")}.pdf`
          : "certification_" + new Date().getTime() + ".pdf";
      return {
        margin: this.nameFile != "" ? [0.3, 0] : 0,
        filename: setFileName,
        pagebreak: {
          mode: "legacy",
        },
        image: {
          type: "jpeg",
          quality: 2,
          style: { "object-fit": "contain" },
        },
        enableLinks: true,
        html2canvas: {
          scale: 1,
          useCORS: true,
        },
        jsPDF: {
          unit: "in",
          format: "a4",
          orientation: "portrait",
        },
        // style: { "object-fit": "cover" },
      };
    },
  },
  methods: {},
};
</script>
