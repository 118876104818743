<template>
  <div class="pd-4 pd-y-5">
    <i class="fas fa-bars" @click="$emit(`swiftSide`)"></i>
    <el-dropdown trigger="click">
      <div class="el-dropdown-link d-flex ai-center set-nav-dropdown">
        <el-avatar
          icon="el-icon-user-solid"
          class="mg-r-3"
          :src="
            user.profileImage != ''
              ? imageUrl + 'profile/' + user._id + '/' + user.profileImage
              : ''
          "
        ></el-avatar>
        <span class="mg-r-3 font-14 color-35">
          {{ user.name }} {{ user.surname }}
          <br />
          <span class="color-main-2 font-weight-bold">
            Role:
            {{
              user.role == 80
                ? "Board"
                : user.role == 60
                ? "Admin"
                : user.role == 50
                ? "Auditor"
                : ""
            }}
          </span>
        </span>
        <i class="fas fa-chevron-down font-16 pd-t-5"></i>
      </div>
      <el-dropdown-menu slot="dropdown" class="dropdown-over">
        <el-dropdown-item class="is-flex">
          <img alt="logo" src="@/assets/icon/profile.png" class="mw-100" />
          <div @click="$router.push(`/profile`)">ข้อมูลส่วนตัว</div>
        </el-dropdown-item>
        <el-dropdown-item class="is-flex">
          <img
            alt="logo"
            src="@/assets/icon/change-password.png"
            class="mw-100"
          />
          <div @click="$router.push(`/change-password`)">เปลี่ยนรหัสผ่าน</div>
        </el-dropdown-item>
        <el-dropdown-item class="is-flex">
          <img alt="logo" src="@/assets/icon/signout.png" class="mw-100" />
          <div @click="signOut">ออกจากระบบ</div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { HTTP } from "@/service/axios";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  methods: {
    signOut() {
      window.localStorage.removeItem("UIC");
      document.cookie = "auth=;";
      delete HTTP.defaults.headers.common.Authorization;
      delete HTTP.defaults.headers.common["X-CSRF-TOKEN"];

      setTimeout(() => {
        this.$router.push(`/login`);
      }, 250);
    },
  },
  data() {
    return {
      imageUrl: process.env.VUE_APP_BASE_URL_IMAGE,
    };
  },
};
</script>
