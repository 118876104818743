<template>
  <div class="app-wrapper">
    <SideNav
      class="sidebar-container"
      :class="openSide == true ? 'active' : ''"
      @swiftSide="() => (openSide = !openSide)"
    />
    <div
      id="overlay"
      v-if="openSide"
      @click="openSide = !openSide"
      :class="openSide == true ? 'active' : ''"
    ></div>
    <div class="main-container">
      <Navbar
        v-if="showNavbar"
        id="nav-bar"
        @swiftSide="() => (openSide = !openSide)"
      />
      <div class="content" :class="{ full: !footer }">
        <div class="padding-in-content mg-t-5">
          <el-card shadow="never" class="custom-card-full">
            <slot />
          </el-card>
        </div>
      </div>
      <!-- <div v-if="footer">
        <footer class="text-center pd-y-4">
          สงวนลิขสิทธิ์ พ.ศ. 2565 บริษัท ไลฟ์ฟินคอร์ป จำกัด
        </footer>
      </div> -->
    </div>
  </div>
</template>

<script>
import SideNav from "@/components/sideNav/SideNav";
import Navbar from "@/components/sideNav/Navbar";
import { mapState } from "vuex";
export default {
  components: {
    SideNav,
    Navbar,
  },
  props: {
    showNavbar: {
      type: Boolean,
      default: true,
    },
    footer: {
      typeof: Boolean,
      return: false,
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  data() {
    return {
      openSide: false,
    };
  },
};
</script>
